export default {
  methods: {
    showField(id) {
      let form = this.$parent.form ? this.$parent.form : this.form

      if (!this.memberSpecified && id !== "member_id") {
        return false
      } else if (id === "member_id") {
        return true
      }

      if (this.fieldNotEmpty(form[id])) {
        return true
      }

      const supportsLettings = this.fieldEquals(form.supports_lettings, true)
      const supportsEmployment = this.fieldEquals(
        form.supports_employment,
        true
      )

      const supportsOnlyLettings = supportsLettings && !supportsEmployment
      const supportsOnlyEmployment = supportsEmployment && !supportsLettings
      const optedInToJobs = this.fieldEquals(form.jobs_opt_in, true)
      const optedOutOfJobs = this.fieldEquals(form.jobs_opt_in, false)
      const appliedForJobsRecently = this.fieldStartsWith(
        form.applied_recently,
        "yes"
      )
      const didNotApplyToJobsRecently = this.fieldStartsWith(
        form.applied_recently,
        "no"
      )
      const isParent = this.fieldStartsWith(form.parent, "yes")
      const doNotDoJobsFirst = this.fieldStartsWith(
        form.do_jobs_first,
        "i don't want"
      )
      const optedInToHomes = this.fieldEquals(form.homes_opt_in, true)
      const optedOutOfHomes = this.fieldEquals(form.homes_opt_in, false)
      const notOptedOutOfHomes =
        this.fieldEmpty(form.homes_opt_in) || optedInToHomes
      const mightStillWantHomes = supportsLettings && notOptedOutOfHomes

      const skippingSafetyAssessment = this.fieldStartsWith(
        form.safety_assessment_intro,
        "no"
      )

      if (skippingSafetyAssessment) {
        const formKeys = Object.keys(form)
        const currentFormIndex = formKeys.indexOf(id)
        const safetyAssessmentIndex = formKeys.indexOf(
          "safety_assessment_intro"
        )
        const finalQuestions = [
          "data_and_consent_form",
          "data_and_consent_form_signed",
          "wrap_up",
        ]
        if (currentFormIndex > safetyAssessmentIndex && id !== "wrap_up") {
          return false
        }
      }

      switch (id) {
        case "jobs_opt_in":
          // Question is shown if the contract the member is linked to is marked as offering the jobs service
          return supportsEmployment
        case "why_not_job":
          return optedOutOfJobs
        case "currently_in_job":
          return optedInToJobs || optedInToHomes
        case "current_job_type":
        case "current_job_contract":
        case "current_job_pay":
          return this.fieldEquals(form.currently_in_job, true)
        case "why_job":
          return optedInToJobs && !optedInToHomes
        case "work_history_details":
        case "last_year_worked":
        case "applied_recently":
          return optedInToJobs
        case "job_application_details":
          return appliedForJobsRecently
        case "job_search_blockers":
        case "types_of_job":
          return didNotApplyToJobsRecently
        case "hours_per_week":
        case "job_schedule":
          return optedInToJobs
        case "parent":
          return (
            optedInToJobs ||
            (supportsLettings && optedOutOfJobs) ||
            supportsOnlyLettings
          )
        case "number_of_children":
          return isParent
        case "childcare_options":
          return isParent && optedInToJobs
        case "homes_opt_in":
          return supportsLettings
        case "why_not_homes":
          return optedOutOfHomes
        case "do_jobs_first":
          return optedInToHomes && optedInToJobs
        case "why_not_jobs_first":
          return doNotDoJobsFirst
        case "homes_start_1":
          return this.fieldStartsWith(form.do_jobs_first, "yes")
        case "jobs_only_funding_needed":
          return optedInToJobs && (optedOutOfHomes || supportsOnlyEmployment)
        case "neither_service_warning":
          return (
            (supportsOnlyEmployment && optedOutOfJobs) ||
            (supportsOnlyLettings && optedOutOfHomes) ||
            (optedOutOfHomes && optedOutOfJobs)
          )
        case "jobs_only_funding_items":
          return this.fieldEquals(form.jobs_only_funding_needed, true)
        case "homes_other_adults":
          return mightStillWantHomes
        case "homes_other_adults_details":
        case "homes_other_adults_income":
          return this.fieldEquals(form.homes_other_adults, true)
        case "homes_other_adults_income_amount":
          return (
            this.fieldContains(
              form.homes_other_adults_income,
              "income from a job"
            ) ||
            this.fieldContains(form.homes_other_adults_income, "benefits") ||
            this.fieldContains(form.homes_other_adults_income, "other income")
          )
        case "homes_pets":
          return mightStillWantHomes
        case "homes_pets_details":
          return this.fieldEquals(form.homes_pets, true)
        case "homes_debt":
          return mightStillWantHomes
        case "homes_debt_details":
        case "homes_debt_management":
          return this.fieldEquals(form.homes_debt, true)
        case "homes_budget":
          return mightStillWantHomes
        case "better_off_spreadsheet_url":
          return this.fieldEquals(form.homes_budget, true)
        case "rented_private_property_before":
          return optedInToHomes
        case "housing_risk_management":
        case "housing_support_details":
          return !this.fieldEquals(form.housing_status, "No housing issues")
        case "mental_health_high_risk_details":
          return this.fieldStartsWith(form.mental_health_high_risk, "yes")
        case "mental_health_contact":
          return this.fieldStartsWith(form.mental_health_support, "yes")
        case "homes_accessibility":
          return optedInToHomes
        case "homes_accessibility_details":
          return (
            this.fieldContains(
              form.homes_accessibility,
              "I have accessibility requirements"
            ) ||
            this.fieldContains(
              form.homes_accessibility,
              "Someone in my household has accessibility requirements"
            )
          )
        case "physical_health_prescriptions":
        case "gp_contact_details":
          return this.fieldStartsWith(form.physical_health_medication, "yes")
        case "substance_history":
        case "substance_use":
        case "substance_risk_management":
        case "substance_support":
          return (
            this.fieldStartsWith(form.alcohol_use, "yes") ||
            this.fieldStartsWith(form.drug_use, "yes")
          )
        case "substance_contact":
          return this.fieldStartsWith(form.substance_support, "yes")
        case "gambling_details":
        case "gambling_risk_management":
        case "gambling_support":
          return this.fieldStartsWith(form.gambling, "yes")
        case "gambling_contact":
          return this.fieldStartsWith(form.gambling_support, "yes")
        case "offending_history":
        case "offending_risk_management":
        case "probation":
          return this.fieldStartsWith(form.criminal_record, "yes")
        case "probation_details":
          return this.fieldStartsWith(form.probation, "yes")
        case "risk_to_safety_details":
          return this.fieldStartsWith(form.personal_safety_risk, "yes")
        case "emergency_contact":
          return this.fieldStartsWith(form.emergency_contact_opt_in, "yes")
        case "national_insurance_number":
          return this.fieldEquals(form.commssioner_is_dwp, true)
        case "months_in_temporary_accommodation":
          return this.fieldContains(
            form.housing_status,
            "temporary accommodation"
          )
        case "number_of_temporary_accommodation_bedrooms":
          return this.fieldContains(
            form.housing_status,
            "temporary accommodation"
          )
        default:
          return true
      }
    },
  },
  computed: {
    memberSpecified() {
      let form = this.$parent.form ? this.$parent.form : this.form

      return !this.fieldEmpty(form.member_id)
    },
  },
}
